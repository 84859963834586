import React from 'react'
import { Link } from 'gatsby'

import Layout from '@/components/layout'
import SEO from '@/components/seo'

import './style.css'

const NotFoundPage = () => (
	<Layout>
		<SEO title="404: Not found" />
		<section className="page_404">
			<div className="container">
				<div className="four_zero_four_bg">
					<h1>404</h1>
				</div>
				<div className="contant_box_404">
					<h3>Look like you're lost</h3>
					<p>The page you are looking for not avaible!</p>
					<Link to="/">Go to Home</Link>
				</div>
			</div>
		</section>
	</Layout>
)

export default NotFoundPage
